var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Size" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("Fancy a small or large rating control? Simply set the prop ")
        ]),
        _c("code", [_vm._v("size")]),
        _c("span", [_vm._v(" to either ")]),
        _c("code", [_vm._v("'sm'")]),
        _c("span", [_vm._v(" or ")]),
        _c("code", [_vm._v("'lg'")]),
        _c("span", [_vm._v(" respectively.")])
      ]),
      _c(
        "div",
        { staticClass: "mb-1" },
        [
          _c("b-form-rating", {
            attrs: {
              id: "rating-sm",
              size: "sm",
              inline: "",
              variant: "primary"
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-1" },
        [
          _c("b-form-rating", {
            attrs: { id: "rating-md", inline: "", variant: "primary" },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("b-form-rating", {
            attrs: {
              id: "rating-lg",
              size: "lg",
              inline: "",
              variant: "primary"
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }