var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Number of stars" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeNumber) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v(" You can change the number of stars via the ")]),
        _c("code", [_vm._v("stars")]),
        _c("span", [_vm._v(" prop. The minimum allowed stars is ")]),
        _c("code", [_vm._v("3")])
      ]),
      _c("label", { staticClass: "mr-1", attrs: { for: "rating-10" } }, [
        _vm._v("Rating with 10 stars:")
      ]),
      _c("b-form-rating", {
        attrs: {
          id: "rating-10",
          stars: "10",
          inline: "",
          "no-border": "",
          variant: "warning"
        },
        model: {
          value: _vm.value10,
          callback: function($$v) {
            _vm.value10 = $$v
          },
          expression: "value10"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }