var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "match-height" },
    [
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-rating-default")], 1),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-rating-number-of-stars")],
        1
      ),
      _c("b-col", { attrs: { cols: "12" } }, [_c("form-rating-variants")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-rating-borderless")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-rating-size")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-rating-show-value")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-rating-state")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-rating-inline-mode")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-rating-icon")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-rating-input-group")], 1),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-rating-internationalization")],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [_c("form-rating-clear-button")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }