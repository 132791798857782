var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "State" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDisabled) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "If you require additional information before a user can chose a ratings value, simply set the "
          )
        ]),
        _c("code", [_vm._v("disabled")]),
        _c("span", [_vm._v(" prop to ")]),
        _c("code", [_vm._v("true")]),
        _c("span", [
          _vm._v(
            " to disable any user interactivity on the component. Also you can make ratings readonly using "
          )
        ]),
        _c("code", [_vm._v("readonly")]),
        _c("span", [_vm._v(" prop")])
      ]),
      _c(
        "div",
        [
          _c(
            "label",
            { staticClass: "mr-1 mb-2", attrs: { for: "rating-disabled" } },
            [_vm._v("Readonly rating")]
          ),
          _c("b-form-rating", {
            attrs: {
              id: "rating-disabled",
              variant: "warning",
              value: "2.75",
              readonly: "",
              "no-border": "",
              inline: ""
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "label",
            { staticClass: "mr-1", attrs: { for: "rating-disabled" } },
            [_vm._v("Disabled rating")]
          ),
          _c("b-form-rating", {
            attrs: {
              id: "rating-disabled",
              value: "2.75",
              disabled: "",
              "no-border": "",
              inline: ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }