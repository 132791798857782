var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Variants" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeVariant) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "Easily apply one of the Bootstrap theme color variants to the rating icon via the "
          )
        ]),
        _c("code", [_vm._v("variant")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-form-radio",
            {
              staticClass: "custom-control-primary",
              attrs: { name: "some-radio9", value: "primary" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Primary ")]
          ),
          _c(
            "b-form-radio",
            {
              staticClass: "custom-control-secondary",
              attrs: { name: "some-radio9", value: "secondary" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Secondary ")]
          ),
          _c(
            "b-form-radio",
            {
              staticClass: "custom-control-success",
              attrs: { name: "some-radio9", value: "success" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Success ")]
          ),
          _c(
            "b-form-radio",
            {
              staticClass: "custom-control-danger",
              attrs: { name: "some-radio9", value: "danger" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Danger ")]
          ),
          _c(
            "b-form-radio",
            {
              staticClass: "custom-control-warning",
              attrs: { name: "some-radio9", value: "warning" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Warning ")]
          ),
          _c(
            "b-form-radio",
            {
              staticClass: "custom-control-info",
              attrs: { name: "some-radio9", value: "info" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [_vm._v(" Info ")]
          )
        ],
        1
      ),
      _c("b-form-rating", {
        staticClass: "mt-1",
        attrs: { variant: _vm.selected, inline: "", "no-border": "" },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }