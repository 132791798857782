var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Internationalization" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeInternationalization) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("When a ")]),
        _c("code", [_vm._v("locale")]),
        _c("span", [_vm._v(" is specified, the displayed value (when the ")]),
        _c("code", [_vm._v("show-value")]),
        _c("span", [_vm._v(" prop is ")]),
        _c("code", [_vm._v("true")]),
        _c("span", [_vm._v(" ) will be in the browser's default locale.")])
      ]),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("b-form-select", {
            attrs: { options: _vm.locales },
            model: {
              value: _vm.locale,
              callback: function($$v) {
                _vm.locale = $$v
              },
              expression: "locale"
            }
          }),
          _c("b-form-rating", {
            attrs: {
              locale: _vm.locale,
              "show-value": "",
              variant: "warning",
              precision: "1",
              inline: "",
              "no-border": ""
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }